import React from 'react';

const EmailVerifyMessage = () => {
  return (
    <div className='container my-5'>
      <h3>이메일 인증메일을 보냈습니다. 인증절차를 완료해주세요^^!</h3>
    </div>
  );
};

export default EmailVerifyMessage;
