export const FETCH_USER = 'FETCH_USER';
export const AUTH_ERROR_CODE = 'AUTH_ERROR_CODE';
export const SIGN_OUT = 'SIGN_OUT';
export const VERIFICATION_STATUS = 'VERIFICATION_STATUS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SUBMIT_PRO = 'SUBMIT_PRO';
export const FETCH_PRO = 'FETCH_PRO';

// Artists Action Types
export const CREATE_ARTIST = 'CREATE_ARTIST';
export const FETCH_ARTISTS = 'FETCH_ARTISTS';
export const FETCH_PENCIL_ARTISTS = 'FETCH_PENCIL_ARTISTS';
export const FETCH_EDITION_ARTISTS = 'FETCH_EDITION_ARTISTS';
export const FETCH_PHOTODESIGN_ARTISTS = 'FETCH_PHOTODESIGN_ARTISTS';
export const FETCH_ARTIST = 'FETCH_ARTIST';
export const EDIT_ARTIST = 'EDIT_ARTIST';
export const DELETE_ARTIST = 'DELETE_ARTIST';
export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';

// Products Action Types
export const FETCH_ONE_ARTIST_PRODUCTS = 'FETCH_ONE_ARTIST_PRODUCTS';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const FETCH_PENCIL_PRODUCTS = 'FETCH_PENCIL_PRODUCTS';
export const FETCH_ILLUSTRATION_PRODUCTS = 'FETCH_ILLUSTRATION_PRODUCTS';
export const FETCH_PHOTOSHOP_PRODUCTS = 'FETCH_PHOTOSHOP_PRODUCTS';

// ETC.
export const START_CHECKOUT = 'START_CHECKOUT';
export const ORDER_PRODUCT = 'ORDER_PRODUCT';
